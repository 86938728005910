import React from 'react'
import { Fragment } from 'react'
import zin from '../assets/zin.svg'

function Footer() {
  return (
    <Fragment>

    <footer className="footer1">
        <div className="part1f">
            <div className="part1-1f">

                <div className="part1-1fa">
                    <img src={zin} alt="zin" />
                </div>

                <div className="part1-1fo">
                    <div className="you">
                        <i class="fa-brands fa-square-instagram"></i>
                        <a href="http://instagram.com/chzain"> instragram</a>
                    </div>
                    <div className="you you2">
                        <i class="fa-brands fa-youtube"></i>
                                             
                        <a href="http://facebook.com/chzain"> facebook</a>
                    </div>
                    <div className="you you3">
                        <i class="fa-brands fa-facebook"></i>    
                        <a href="http://youtoub.com/chzain"> youtoub</a>
                    </div>

                </div>
            </div>
            <div className="part1-2f">
                <div className="part1-2fa">
                    <h4>CONTACTS</h4>
                    <p>03021729190</p>
                    <p>zainulislam053@gmail.com</p>
                </div>
                <div className="part1-2fo">
                    <h4>ADRESS</h4>
                    <p>muhallha jugewalla,<br></br>kot radha kishen,<br></br>punjab pakistan,</p>

                </div>
            </div>
        </div>
    </footer>
</Fragment>
  )
}

export default Footer