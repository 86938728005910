import React from 'react'
import { Fragment } from 'react'
import vg from "../assets/pic2.webp"
import { AiFillGoogleCircle, AiFillAmazonCircle, AiFillYoutube, AiFillInstagram } from "react-icons/ai"

function Home() {
    return (
        <Fragment >
            <div className='zim'>

                <div className='home'id='home'>
                    <main>

                        <h1>demo  website</h1>
                        <p>Solution toall your problems </p>
                    </main>

                </div>

                <div className='home2'>
                    <img src={vg} alt='Graphics' />

                    <div>
                        <p>
                            We are you one an only solution to tht teach problems you face every day We are leading tech company whose aim is to increase the problem solving ability in children.
                        </p>
                    </div>
                </div>

                <div className='home3'id="about">
                    <div>
                        <h1>Who we are?</h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati dolorum tempore sapiente quidem ipsum magnam blanditiis quia. Non quis illo ipsum dolor eligendi quas voluptatibus maxime aliquid laudantium animi, deleniti nobis illum rem provident, hic, eaque quae nam. A possimus quibusdam aut eveniet animi hic, cum mollitia delectus. Praesentium dolorem dolorum dignissimos facere earum distinctio ad ut assumenda a cupiditate. Harum, nostrum minus quam expedita nobis dolorum aliquam veniam illum accusantium fugit ad soluta? Ea quas fugit harum maxime. Assumenda, numquam! Eligendi, harum mollitia? Cumque nostrum aliquam eius ut quam corrupti quaerat nemo libero? Delectus in praesentium nemo, eaque tempore itaque.</p>
                    </div>
                </div>

                <div className='home4' id="brands">
                    <div>

                        <h1>Brands</h1>

                        <article>
                            <div style={{
                                animationDelay: "0.3s"
                            }}>
                                <AiFillGoogleCircle />
                                <p>Google</p>
                            </div>

                            <div style={{
                                animationDelay: "0.5s"
                            }}>
                                <AiFillAmazonCircle/>
                                <p>Amazon</p>
                            </div>

                            <div style={{
                                animationDelay: "0.7s"
                            }}>
                                <AiFillYoutube />
                                <p>Youtube</p>
                            </div>

                            <div style={{
                                animationDelay: "1s"
                            }}>
                                <AiFillInstagram />
                                <p>Instagram</p>
                            </div>

                        </article>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default Home